@import 'styles/import.scss';

.serviceMainContainer {
  padding: 72px 40px;
  display: block;
  @include media-breakpoint-up(md) {
    padding: 72px 0;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    :local(.title) {
      margin-bottom: 0;
      justify-content: flex-start;
    }
  }
}

.title {
  @include text-xl;
  color: $blue_dark;
  margin-bottom: 50px;
  width: 100%;
  justify-content: space-between;
}

.text {
  @include text-sm;
  color: $blue_dark;
  @include media-breakpoint-up(md) {
    margin-top: auto;
  }
}

.serviceMainWrapper,
.serviceSubWrapper {
  &::before,
  &::after {
    display: none;
  }
  &:first-child {
    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid $grey25;
    }
  }
  &:hover {
    background-color: $grey1;
    :local(.title) {
      color: $blue;
    }
  }
  @include media-breakpoint-up(md) {
    &:first-child {
      &::after {
        display: none;
      }
    }
  }
}

.imageWrapper {
  border-bottom: 1px solid $grey25;

  @include media-breakpoint-up(md) {
    @include make-col(6);
    border-bottom: 0;
    border-left: 1px solid $grey25;
    > span {
      height: 100% !important;
    }
  }
}

.serviceMainImageWrapper {
  @include media-breakpoint-up(md) {
    display: flex;
    height: 560px;
  }
}

.serviceMainWrapper {
  @include media-breakpoint-up(md) {
    @include make-col(6);
    padding: 0 40px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 80px;
  }
}

.serviceSubWrapper {
  @include media-breakpoint-up(md) {
    @include make-col(6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
}

.serviceSubContainer {
  padding: 64px 40px;
  display: block;
  height: 100%;
  :local(.title) {
    margin-bottom: 118px;
  }
  @include media-breakpoint-up(md) {
    padding: 64px 40px;
    border-right: 1px solid $grey25;
  }
  @include media-breakpoint-up(lg) {
    padding: 64px 80px;
  }
}

.servicesSubWrapper {
  @include media-breakpoint-up(md) {
    display: flex;
  }
}
